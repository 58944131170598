import React from "react";
import Typography from "@mui/material/Typography";

function Step1Header() {
  return (
    <>
      <Typography paragraph={true}>
        Filling in the form above will instantly give you a list of estimates
        for structural inspections on your property from our partners at Local
        Surveyors Direct.
      </Typography>
      <Typography paragraph={true}>
        These estimates will come from Structural Engineers and or Building
        Surveyors who cover your local area. You will also receive their full
        contact details. You will then be able to contact any of these suppliers
        directly. In addition Local Surveyors Direct will forward your contact
        details to each of these suppliers so that they can contact you directly
        should they wish to. If you do not want to give your phone number please
        enter 0000 into the telephone number field.
      </Typography>

      <Typography paragraph={true}>
        Local Surveyors Direct will NOT use your contact details again, other
        than a brief feedback email to check that you were happy with the
        service provided. Please read{" "}
        <a
          href="https://www.localsurveyorsdirect.co.uk/customer-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          LSD&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
      <Typography paragraph={true}>
        The HomeOwners Alliance will NOT use your contact details unless you
        have opted in to receive our free newsletter. Please read{" "}
        <a
          href="https://hoa.org.uk/our-site/home-owners-alliance-privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          HOA&apos;s Privacy Policy
        </a>{" "}
        for more information.
      </Typography>
    </>
  );
}

export default Step1Header;
